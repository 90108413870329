import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DailyIframe from '@daily-co/daily-js';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MakeAcall() {
  const [myRoomUrl, setMyRoomUrl] = useState(null);
  const [userName, setUserName] = useState(null);  // State to hold username
  const location = useLocation();
  const callFrameRef = useRef(null);
  const navigate = useNavigate();

  // Retrieve room URL and username from the previous page
  useEffect(() => {
    const data = location.state;
    if (data) {
      setMyRoomUrl(data.myRoomUrl);
      setUserName(data.loggedInUser);  // Set username programmatically
    }
  }, [location.state]);

  const customTheme = {
    colors: { accent: '#FF385C' },
    fonts: { sans: 'Helvetica, Arial, sans-serif' },
  };

  useEffect(() => {
    if (myRoomUrl && !callFrameRef.current) {
      callFrameRef.current = DailyIframe.createFrame({
        showLeaveButton: true,
        iframeStyle: {
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        },
        theme: customTheme,
      });

      callFrameRef.current.join({ url: myRoomUrl, userName })  // Use username in join options
        .then(() => {
          console.log(`Joined room: ${myRoomUrl} as ${userName}`);
          
          callFrameRef.current.on('participant-joined', () => {
            const participants = callFrameRef.current.participants();
            if (Object.keys(participants).length === 2) {
              toast.success("Your session starts now!");
            }
          });

          callFrameRef.current.on('leave', () => {
            navigate('/');
          });

        })
        .catch(error => console.error("Error joining room:", error));
    }

    return () => {
      callFrameRef.current?.destroy();
      callFrameRef.current = null;
    };
  }, [myRoomUrl, userName, navigate]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
    </div>
  );
}

export default MakeAcall;
  