import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DailyIframe from '@daily-co/daily-js';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function OnVideoCall() {
  const [myRoomUrl, setMyRoomUrl] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const location = useLocation();
  const callFrameRef = useRef(null);
  const navigate = useNavigate();
  const [timerStarted, setTimerStarted] = useState(false);

  // Receive data from the previous page
  useEffect(() => {
    const data = location.state;
    if (data) {
      setMyRoomUrl(data.myRoomUrl);
      setMinutes(data.minutes);
    }
  }, [location.state]);

  const definedMinutes = parseInt(minutes); 
  const roomUrl = myRoomUrl;
  const callDuration = definedMinutes ? definedMinutes * 60 * 1000 : 0; // Duration based on minutes

  // Define your custom theme
  const customTheme = {
    colors: {
      accent: '#FF385C',
    },
    fonts: {
      sans: 'Helvetica, Arial, sans-serif', // Custom font for UI elements
    },
  };

  useEffect(() => {
    if (roomUrl && !callFrameRef.current) { // Check if roomUrl is valid
      callFrameRef.current = DailyIframe.createFrame({
        showLeaveButton: true,
        iframeStyle: {
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        },
        theme: customTheme, // Include the custom theme here
      });

      callFrameRef.current.join({ url: roomUrl })
        .then(() => {
          console.log(`Joined room: ${roomUrl}`);
          
          // Listen for the second participant joining
          callFrameRef.current.on('participant-joined', () => {
            const participants = callFrameRef.current.participants();
            if (Object.keys(participants).length === 2 && !timerStarted) {
              setTimerStarted(true);
              
              // Show toast that session is starting
              toast.success("Your session starts now!");

              // Start the countdown to show a warning 1 minute before end
              const toastTimer = setTimeout(() => {
                toast.warn("Your session is about to end in 1 minute.", { autoClose: 60000 });
              }, callDuration - 60000);

              // Start the countdown to eject after the call duration
              const ejectTimer = setTimeout(() => {
                console.log("Time's up! Ejecting user from the room...");
                callFrameRef.current?.leave();
                navigate('/'); // Navigate to /home on ejection
              }, callDuration);

              // Cleanup on unmount
              return () => {
                clearTimeout(toastTimer);
                clearTimeout(ejectTimer);
                callFrameRef.current?.destroy();
                callFrameRef.current = null;
              };
            }
          });

          // Navigate to /startagain when leaving the room
          callFrameRef.current.on('leave', () => {
            navigate('/'); // Navigate to /startagain when leaving the call
          });

        })
        .catch(error => console.error("Error joining room:", error));
    }
  }, [roomUrl, callDuration, navigate, timerStarted]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
    </div>
  );
}

export default OnVideoCall;
  