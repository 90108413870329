import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css'; // Skeleton CSS 
import { Routes, Route, BrowserRouter } from "react-router-dom";
import './App.css';
import './Responsive.css';
import Home from './Pages/Home';
import ViewUser from './Pages/ViewUser';
import SignUp from './Pages/SignUp';
import Register from './Pages/Register';
import { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth"; 
import ResetPassword from './Pages/ResetPassword';
import Login from './Pages/Login';
import AccSetUp from './Pages/AccSetUp';
import AccountSetuPul from './Pages/AccountSetuPul';
import ServicesSetup from './Pages/ServicesSetup';
import Profile from './Pages/Profile';
import EditProfile from './Pages/EditProfile';
import AboutUs from './Pages/AboutUs';
import HelpCentre from './Pages/HelpCentre';
import NewsUpdates from './Pages/NewsUpdates';
import CommunityGuidelines from './Pages/CommunityGuidelines';
import TermsOfService from './Pages/TermsOfService';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Faqs from './Pages/Faqs';
import SafetyPage from './Pages/SafetyPage';
import RefundPolicy from './Pages/RefundPolicy';
import UpgradeAccount from './Pages/UpgradeAccount';
import { LocationProvider } from './LocationContext'; // Import the LocationProvider
import ViewMore from './Pages/ViewMore';
import StartAcall from './Pages/StartAcall';
import { getDatabase, ref, onDisconnect, set } from "firebase/database";
import { app } from './firebase.js';
import OnVideoCall from './Pages/OnVideoCall.js';
import MakeAcall from './Pages/MakeAcall.js';
 

function App() {

  const auth = getAuth();

  useEffect(() => {
    const db = getDatabase(app);

    // Listen for changes in authentication state
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const userId = user.uid;
        const userStatusRef = ref(db, `/status/${userId}`);

        // User is online
        const onlineStatus = {
          state: "online",
          last_changed: Date.now(),
          userId:userId
        };

        const offlineStatus = {
          state: "offline",
          last_changed: Date.now(),
          userId:userId
        };

        // Set user as online when connected
        set(userStatusRef, onlineStatus);

        // Set user as offline automatically when disconnected
        onDisconnect(userStatusRef).set(offlineStatus);
      }
    });
  }, [auth]);
  
  return (
    <div className="App">
    <LocationProvider>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Home />} /> 
              <Route path="/view-user" element={<ViewUser />} /> 
              <Route path="/signup" element={<SignUp />} />  
              <Route path="/register" element={<Register />} />
              <Route path="/reset-password" element={<ResetPassword />} /> 
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/login" element={<Login />} /> 
              <Route path="/account-set-up" element={<AccountSetuPul />} />
              <Route path="/services-set-up" element={<ServicesSetup />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/edit-profile" element={<EditProfile />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/help-centre" element={<HelpCentre />} />
              <Route path="/news-and-updates" element={<NewsUpdates />} />
              <Route path="/community-guidelines" element={<CommunityGuidelines />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/faqs" element={<Faqs />} />
              <Route path="/safety-and-security" element={<SafetyPage />} />
              <Route path="/refund-policy" element={<RefundPolicy />} />
              <Route path="/get-verified" element={<UpgradeAccount />} />
              <Route path="/view-more" element={<ViewMore />} />
              <Route path="/call" element={<StartAcall />} />
              <Route path="/oncall" element={<OnVideoCall />} />
              <Route path="/make-a-call" element={<MakeAcall />} />
          </Routes>
      </BrowserRouter> 
    </LocationProvider>

    </div>
  );
}

export default App;
